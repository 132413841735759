.tag {
  white-space: normal;
}

.capitalize {
  display: inline-block;

  &::first-letter {
    text-transform: capitalize;
  }
}
