@import "src/styles/mixins.breakpoints";

.root {
  position: sticky;
  top: 0;
  min-height: 100dvh;
  padding: 20px;
  overflow: hidden;
  overflow-y: auto;

  @include media($lg) {
    padding: 35px 35px 10px;
  }

  @include media($xl) {
    padding: 35px 65px 20px 65px;
  }

  @include media($xl) {
    padding: 35px 70px 20px 70px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @include media($xl) {
    gap: 30px;
  }
  @include media($xxl) {
    gap: 40px;
  }
}

.mainLogo {
  height: 30px;

  svg {
    width: 55%;
    height: 100%;

    @include media($sm) {
      width: auto;
    }
  }
}

.title {
  font-weight: 700 !important;
  margin: 0 !important;
  font-size: 20px !important;

  & .subtitle {
    font-weight: 400 !important;
  }

  @include media($md) {
    font-size: 24px !important;
  }

  @include media($xl) {
    font-size: 26px !important;
  }

  @include media($xxl) {
    font-size: 36px !important;
  }
}

.br {
  display: none;

  @include media($md) {
    display: inline;
  }
}

.items {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.item {
  font-weight: 500;
  font-size: 16px;

  @include media($xl) {
    font-size: 18px;
  }

  @include media($xxl) {
    font-size: 20px;
  }
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;

  @include media($lg) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.review {
  width: 100%;
  max-width: 760px;
  background-color: white;
  padding: 16px;
  border-radius: 8px;

  @include media($sm) {
    padding: 20px;
  }
  @include media($lg) {
    padding: 24px;
  }
  @include media($xl) {
    padding: 32px;
  }
  @include media($xxl) {
    padding: 24px 40px;
  }
}

.figure {
  margin: 0;
  color: #4b5563;
}

.stars {
  display: flex;
  gap: 4px;
  color: #f59e0b;
}

.star {
  font-size: 20px;

  @include media($xl) {
    font-size: 24px;
  }
}

.quote {
  margin-top: 16px;
  font-weight: 700;
  line-height: 1.5;
  color: #4b5563;

  font-size: 16px;

  @include media($xl) {
    margin-top: 20px;
    font-size: 18px;
  }

  @include media($xxl) {
    font-size: 20px;
  }
}

.figcaption {
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 16px;

  @include media($xl) {
    gap: 24px;
    margin-top: 20px;
  }
}

.avatar {
  width: 64px;
  height: 64px;
  border-radius: 9999px;
  background-color: #f3f4f6;
}

.author {
  font-weight: 600;
  color: #1f2937;
}

.role {
  display: block;
  margin-top: 2px;
  font-size: 0.75rem;
  color: #6b7280;
}

.logosTitle {
  font-weight: 500;
  font-size: 12px;
  color: #6b7280;

  @include media($md) {
    color: black;
    font-size: 16px;
  }

  @include media($xl) {
    font-size: 18px;
  }

  @include media($xxl) {
    font-size: 20px;
  }

  &.desktop {
    display: none;

    @include media($sm) {
      display: block;
    }
  }

  &.mobile {
    display: block;

    @include media($sm) {
      display: none;
    }
  }
}

.logosList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 10px;
  gap: 6px 24px;

  @include media($sm) {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }

  @include media($xxl) {
    gap: 32px;
  }

  @include media(1800px) {
    height: 40px;
  }
}

.logo {
  height: 25px;

  @include media($xxl) {
    height: 35px;
  }

  @include media(1800px) {
    height: 40px;
  }
}
