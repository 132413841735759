.outerContainer {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  contain: strict;
}

.innerContainer {
  width: 100%;
  position: relative;
}

.list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
