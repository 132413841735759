.expand {
  position: absolute;
  top: 10px;
  right: 10px;
}

.name {
  display: block;
  padding-right: 14px;
}

.smallText {
  font-size: 14px;
}

.truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
