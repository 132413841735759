.header {
  margin-bottom: 24px;
}

.alignCenter {
  display: flex;
  align-items: center;
}

.tag {
  margin-left: 8px;
}

.card {
  margin-bottom: 24px;
}

.alert {
  margin-top: 8px;
}
