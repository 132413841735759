.emptyState {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  display: block;
}

.item {
  padding: 16px 0;
  border-bottom: 1px solid lightgray;
}

.itemTitle {
  font-weight: bold;
  font-size: 14px;
}
