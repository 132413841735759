.tabs {
  padding: 20px;
  background-color: #f0f2f5;

  &.fullHeight {
    :global(> .ant-tabs-content-holder > .ant-tabs-content) {
      height: 100%;
    }
  }

  &.fullHeightScrollInside {
    max-height: 100dvh;
    height: 100dvh;
    :global(> .ant-tabs-content-holder > .ant-tabs-content) {
      height: 100%;
    }
  }
}

.fullHeightTab {
  height: 100%;
}

.flexTab {
  display: flex;
  flex-direction: column;
}
