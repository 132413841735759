.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  font-size: 14px;
}

$icon-size: 18px;
.icon {
  min-width: $icon-size;
  min-height: $icon-size;
  max-width: $icon-size;
  max-height: $icon-size;
  width: 100%;
  height: 100%;
  margin-top: 2px;
}

.link {
  color: black;

  &:hover {
    color: black;
    text-decoration: underline;
  }
}
