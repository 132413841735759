.icon {
  padding: 5px;
  border-radius: 25%;
  color: white;
  display: flex;
  font-size: 14px;

  &.open {
    background-color: #22c55e;
  }
  &.closed {
    background-color: #ef4444;
  }
}
