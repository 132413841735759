.header {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #f0f2f5;
  margin: 0 -12px;
  padding: 0 12px;
}

.card {
  position: relative;
  outline-offset: -3px;
  cursor: pointer;

  &.active {
    outline: 3px solid #1890ff;
  }
}

.tag {
  display: flex;
  width: max-content;
  max-width: 100%;
  margin: 6px 0 1px 0;
  text-transform: capitalize;
}

.tagText {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.content {
  padding-right: 14px;
}

.admin {
  position: absolute;
  font-size: 12px;
  bottom: 1px;
  right: 6px;
  line-height: 1;
}
