.title {
  font-weight: bolder;
  width: 100%;
  padding: 0 8px;

  @media (min-width: 1350px) {
    position: absolute;
    right: 100%;
    margin-right: 25px;
    padding: 0;
    width: 150px;
    text-align: right;
  }
}

.divider {
  margin: 0px 0 16px 0;
}
