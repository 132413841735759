@import "src/styles/mixins.breakpoints";

.center {
  text-align: center;
}

.button {
  white-space: normal;
  font-size: 12px;
  font-weight: 400;

  > span {
    align-self: auto !important;
  }

  @include media($md) {
    font-weight: 500;
  }
}

.underline,
.underline span {
  text-decoration: underline;
}

.inlineBtn {
  padding-left: 4px;
  padding-right: 4px;
}
