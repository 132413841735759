.list {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
}

.viewButton {
  margin-top: 8px;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
}

.documentItem {
  border-bottom: 1px solid lightgray;
}
