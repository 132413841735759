@import "src/styles/mixins.breakpoints";

.root {
  display: none;

  @include media($lg) {
    display: block;
  }
}

.title {
  font-size: 20px !important;

  @include media($xl) {
    font-size: 22px !important;
  }

  @include media($xxl) {
    font-size: 26px !important;
  }
}

.divider {
  margin: 16px 0 16px;
}
