.fullHeightTabs {
  height: 100%;
  overflow-y: auto;
  padding: 0;

  > :global(.ant-tabs-nav) {
    margin-bottom: 0;
    padding: 0 24px;
  }

  > :global(.ant-tabs-content-holder) {
    height: 100%;

    > :global(.ant-tabs-content) {
      height: 100%;
      padding: 16px 24px 24px;
      overflow-y: auto;
    }
  }
}

.scrollableTab {
  height: 100%;
  overflow: hidden;
  min-width: 0;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
