@import "src/styles/mixins.breakpoints";

.container {
  position: relative;
  padding: 0 !important;

  > * {
    padding: 0 !important;
  }

  & button {
    height: 40px;
  }

  &.fixed {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 101;

    @include media($lg) {
      position: fixed;
    }
  }
}

.button {
  border-radius: 8px;
  background-color: white !important;
}
