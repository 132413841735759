@import "src/styles/mixins.breakpoints";

.hero {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;

  @include media($lg) {
    display: none;
  }
}

.buttons {
  position: sticky;
  bottom: 0;
  z-index: 10;
  background-color: #fff;
  border: none;
  margin: 20px -20px 0;
  padding: 0 20px;
  border-top: 1px solid #e0e0e0;

  @include media($lg) {
    border-top: none;
    margin: 0;
    padding: 0;
    position: relative;
  }
}

.signup {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  margin-top: 10px;
  padding-top: 6px;
  padding-bottom: 6px;

  > span {
    align-self: auto !important;
  }

  &.disabled,
  &.disabled:hover {
    opacity: 0.5;
    color: #fff;
    border-color: #f59e0b;
    background: #f59e0b;
  }

  @include media($md) {
    font-size: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  @include media($xl) {
    margin-bottom: 8px;
  }

  @include media($xl) {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  @include media($xxl) {
    margin-top: 30px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.signupSubtitle {
  font-weight: 400;
  font-size: 14px;

  @include media($md) {
    font-size: 18px;
  }
}
