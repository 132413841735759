.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding-left: 7px;
  padding-right: 14px;

  :global(.ant-btn-loading-icon .anticon) {
    padding-right: 0;
  }
  &:global(.ant-btn-loading) {
    flex-direction: row-reverse;
  }
}

.buttonText {
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
