@import "src/styles/mixins.breakpoints";

.container {
  height: 100dvh;
  width: 100%;
  overflow: auto;
  position: relative;
  background-color: #f3f4f6;
}

.row {
  max-width: $screen-max-width;
  margin: 0 auto;
}

.hero {
  display: none;

  @include media($lg) {
    display: block;
  }
}

.logo {
  height: 20px;
  display: block;
  margin-right: auto;

  @include media(375px) {
    height: 30px;
  }

  @include media($lg) {
    display: none;
  }

  svg {
    width: auto;
    height: 100%;
  }
}

.content {
  position: relative;
  padding: 20px;
  padding-bottom: 0;
  background-color: #ffffff;
  height: 100dvh;
  overflow: auto;

  @include media($lg) {
    min-height: 100dvh;
    height: auto;
    padding: 35px;
    padding-bottom: 10px;
  }

  @include media($xl) {
    padding: 35px 65px 10px 65px;
  }

  @include media($xl) {
    padding: 35px 70px 10px 70px;
  }
}

.contentHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;

  @include media($xl) {
    padding-bottom: 40px;
  }
}

.phone {
  display: none;
  user-select: auto;

  & *::selection {
    background: initial;
    color: initial;
  }

  @include media($md) {
    display: inline-flex;
  }
}
