.container {
  display: grid;
  grid-auto-flow: column;
  overflow-x: auto;
  gap: 20px;
  padding: 0 40px;

  > div {
    position: relative;
    padding-right: 20px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      border-right: 1px solid #e2e4e7;
    }
    &:last-child:before {
      border-right: none;
    }
  }
}
