.imageContainer {
  position: relative;
}

.image {
  width: 100%;
}

.qrCode {
  position: absolute;
  left: 0;
  width: 62%;
  height: auto;
  max-width: 100%;
  left: 50%;
  top: 51%;
  transform: translate(-50%, -51%);
}

.arrow {
  margin-left: 50px;
}
