$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1300px;
$xxl: 1600px;

$screen-max-width: 2000px;

@mixin media($breakpoint) {
  @media screen and (min-width: #{$breakpoint}) {
    @content;
  }
}
