.label {
  display: block;
  margin-bottom: 8px;
  cursor: pointer;
  user-select: none;

  &.inline {
    display: inline;
    margin-bottom: 0px;
    margin-right: 8px;
  }
}
