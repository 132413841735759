$borderRadius: 10px;

div.contentEditable {
  background-color: #fff;
  border-radius: 0 0 $borderRadius $borderRadius;
  border: 1px solid #d9d9d9;
  max-height: 400px;
  overflow-y: auto;

  &.error {
    border-color: #f5222d;
  }
}

div.toolbar {
  border: 1px solid #d9d9d9;
  border-bottom: none;
  border-radius: $borderRadius $borderRadius 0 0;
}
