.inlineSwitch {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;

  label {
    cursor: pointer;
  }
}
