.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 40px;

  .icon {
    transition: transform 0.2s;
  }

  &:hover {
    text-decoration: underline;
    .icon {
      transform: translateX(5px);
    }
  }
}
