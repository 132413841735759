.step {
  width: 22px;
  height: 22px;
  margin: 0 7px 0 -5px;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  border-radius: 22px;
  background-color: rgba(255, 255, 255, 0.65);
  color: #001529;
  transition: background-color 0.3s;
}

.stepDone {
  position: relative;
  color: green;

  &:before {
    position: absolute;
    content: "";
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 22px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    border-radius: 22px;
    border: 1px solid currentColor;
    transition: border-color 0.3s;
  }
}
