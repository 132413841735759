.container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 16px;
}

.interviewerNote[disabled] {
  color: rgba(0, 0, 0, 0.9);
}
