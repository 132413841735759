.button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  &:hover {
    color: black;
    text-decoration: underline;
  }
}

.iconContainer {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 4px;
  width: 48px;
  height: 48px;
  background-color: #1a243a;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  font-size: 24px;
}

.icon {
  color: white;
  display: flex;
  align-self: center;
}

.label {
  font-size: 14px;
}
