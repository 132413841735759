.responseTimeCard {
  background: linear-gradient(90deg, #fdbb2d 0%, #c67700 100%);

  :global {
    .ant-card-body {
      padding: 16px;
    }
    h4.ant-typography {
      margin-bottom: 0;
      color: #fff;
    }
  }
}

.cardHeader {
  font-size: 14px;
  color: #fff;
}
