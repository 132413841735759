$sidebar-width: 300px;
$sidebar-width-collapsed: 60px;
$content-padding: 20px;

.sider {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;
  width: $sidebar-width !important;
  max-width: $sidebar-width !important;
  min-width: $sidebar-width !important;
  flex: 0 0 #{$sidebar-width} !important;

  &.collapsed {
    width: $sidebar-width-collapsed !important;
    max-width: $sidebar-width-collapsed !important;
    min-width: $sidebar-width-collapsed !important;
    flex: 0 0 #{$sidebar-width-collapsed} !important;
  }

  :global {
    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
}

.logo {
  padding: 20px 12px;
  .collapsed & {
    padding: 20px;
  }
}

.menu {
  flex: 1 1 0%;
  overflow: hidden auto;
}

.contentLayout {
  min-height: 100vh;
}

.content {
  height: 100%;
  margin-left: $sidebar-width;

  &.collapsed {
    margin-left: $sidebar-width-collapsed;
  }

  &:not(.noPadding) {
    padding: $content-padding;
  }
}

.superAdmin {
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
