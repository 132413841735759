.input {
  padding-left: 15px;

  :global(.ant-input-prefix) {
    margin-right: 8px;
  }
}

.button {
  text-align: left;
}
