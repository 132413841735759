.listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  gap: 8px;
}
.listItemInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
}
.listItemText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.listItemLink {
  color: inherit;
  text-decoration: inherit;

  &:hover {
    color: inherit;
    text-decoration: underline;
  }
}
.listItemDate {
  font-size: 12px;
}
.listItemDownload {
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;
  min-width: 32px;

  &:hover {
    color: inherit;
  }
}
