.imageContainer {
  position: relative;
}

.image {
  height: 150px;
  object-fit: cover;
}

.imagePlaceholder {
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.coverImageLabel {
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 5px;
  text-align: center;
  z-index: 10;
}

.pagination {
  text-align: center;
}

.uploadMoreButtons {
  width: 100%;
  :global(.ant-upload) {
    width: 100%;
  }
}

%navigation {
  cursor: pointer;
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
}
.navigationLeft {
  @extend %navigation;
  left: -20px;
}
.navigationRight {
  @extend %navigation;
  right: -20px;
}

.navigationIcon {
  font-size: 20px;
  padding: 5px;
  background-color: rgb(128, 128, 128, 0.75);
  color: white;
  border-radius: 50%;
}
