.steps {
  :global(.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after) {
    background-color: rgba(0, 0, 0, 0.25);
  }
}

.step {
  :global(.ant-steps-item-title),
  :global(.ant-steps-item-description) {
    text-decoration: line-through !important;
  }
}
